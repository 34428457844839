







































































import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import GeneralApi from "@/networks/GeneralApi";
import store from "@/store";
@Component({})
export default class EditOffice extends Vue {
  private form: Record<string, unknown> = {};
  private errors: Record<string, Array<string>> = {};

  mounted() {
    if (this.$route.params.id) {
      this.office(this.$route.params.id);
    }
  }
  public async office(id: string): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().getOffice(id);
      this.form = {
        ...res.data.data
      };
      // this.offices = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
  public async submit(): Promise<void> {
    try {
      const form = this.form;
      Object.keys(this.form).forEach((key) => !form[key] && delete form[key]);
      if (!this.$route.params.id) {
        const res: AxiosResponse = await new GeneralApi().storeOffice(form);
        this.errors = {};
        store.commit("showError", {
          message: res.data.message,
          color: "success"
        });
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.form = {};
      } else {
        const res: AxiosResponse = await new GeneralApi().editOffice(
          form,
          this.$route.params.id
        );
        this.errors = {};
        store.commit("showError", {
          message: res.data.message,
          color: "success"
        });
      }
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
